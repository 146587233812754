import { AppstoreAddOutlined, ShopOutlined, DollarOutlined, BarChartOutlined, FilePdfOutlined, MessageOutlined, ToolOutlined, FolderAddOutlined } from '@ant-design/icons';
const menuItems = {
  items: [
      {
          id: 'group-dashboard',
          title: 'Navigation',
          type: 'group',
          children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                icon: BarChartOutlined,
                breadcrumbs: false
            },
            {
                id: 'finances',
                title: 'Finances',
                type: 'item',
                url: '/finances',
                icon: DollarOutlined,
                breadcrumbs: true
            },
            {
                id: 'maintenance',
                title: 'Maintenance',
                type: 'item',
                url: '/maintenance',
                icon: ToolOutlined,
                breadcrumbs: true
            },
            {
                id: 'documents',
                title: 'Documents',
                type: 'item',
                url: '/documents',
                icon: FilePdfOutlined,
                breadcrumbs: false
            },
            {
                id: 'Messages',
                title: 'Messages',
                type: 'item',
                url: '/messages',
                icon: MessageOutlined,
                breadcrumbs: false
            },
          ]
      },
      {
        id: 'group-properties',
        title: 'Properties',
        type: 'group',
        children: [
              {
                  id: 'properties',
                  title: 'Properties',
                  type: 'item',
                  url: '/properties',
                  icon: ShopOutlined,
                  breadcrumbs: false
              },
              {
                  id: 'add-property',
                  title: 'Add Property',
                  type: 'item',
                  url: '/add-property',
                  icon: AppstoreAddOutlined,
                  breadcrumbs: true
              },
        ]
    }
  ]
}

export default menuItems