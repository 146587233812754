import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import MainCard from './components/MainCard'
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Box,
  Checkbox,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Stack
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import {db} from './firebase';

import {
  onSnapshot,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore'

import { 
  getStorage, 
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { getAuth } from 'firebase/auth'

const deleteButton = {
  height: '35px',
  width: '100px',
  fontWeight: 'bold',
  backgroundColor: '#fa4141',
  color: 'white',
  '&:hover': {
    backgroundColor: '#b00000',
  },
}

const selectSingleImageButton = {
  width: '120px',
  height: '120px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  backgroundColor: '#efebf0',
  outline: 0,
  boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)', /* black with 30% opacity */
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: '#0099FF',
    border: '1px solid #0099FF',
    color: 'white',
  },
}

const emptyImageSpace = {
  backgroundColor: '#efebf0',
  width: '120px',
  height: '120px',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 0,
  boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)', /* black with 30% opacity */
  marginRight: '20px'
}

export default function Property() {

  const navigate = useNavigate()
  const { state } = useLocation();

  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  const [ownerName, setOwnerName] = useState('')
  const [ownerEmail, setOwnerEmail] = useState('')
  const [ownerPhone, setOwnerPhone] = useState('')

  const [address, setAddress] = useState('')
  const [propertyType, setPropertyType] = useState('')
  const [sqft, setSqft] = useState(0)
  const [leaseLength, setLeaseLength] = useState(0)
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)
  const [monthlyRent, setMonthlyRent] = useState(0)
  const [imageUrlArray, setImageUrlArray] = useState('')
  const [primaryImageUrl, setPrimaryImageUrl] = useState('')

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  useEffect(() => {

    //  Getting owner data
    const getOwnerData = async () => { 
      const propDocRef = doc(db, "properties", state.id);
      const propDocSnap = await getDoc(propDocRef)
      const ownerId = propDocSnap.data().ownerId

      const ownerDocRef = doc(db, "owners", ownerId);
      const ownerDocSnap = await getDoc(ownerDocRef)
      setOwnerName(ownerDocSnap.data().firstName + ' ' + ownerDocSnap.data().lastName)
      setOwnerEmail(ownerDocSnap.data().email)
      setOwnerPhone(ownerDocSnap.data().phone)
    }
    getOwnerData().catch((error) => alert("Error: " + error))

    //  Getting property data
    onSnapshot(doc(db, "properties", state.id), { includeMetadataChanges: true }, (doc) => {
      const imageUrlArray = []
      for(let i=0; i<doc.data().imageUrlArray.length; i++){
        const image = {
          url: doc.data().imageUrlArray[i],
          isPrimaryImage: doc.data().imageUrlArray[i] === doc.data().primaryImageUrl ? true : false
        }
        imageUrlArray.push(image)
      }
      setAddress(doc.data().address)
      setLeaseLength(doc.data().leaseLength)
      setPropertyType(doc.data().propertyType)
      setSqft(doc.data().sqft)
      setBedrooms(doc.data().bedrooms)
      setBathrooms(doc.data().bathrooms)
      setMonthlyRent(doc.data().monthlyRent)
      setImageUrlArray(imageUrlArray)
      setPrimaryImageUrl(doc.data().primaryImageUrl)
    })
  }, [])

  async function deleteImage(url){
    setImageUploadLoading(true)
    let localImageArray = [...imageUrlArray]
    let primaryImageUrl = ''
    for(let i=0; i<localImageArray.length; i++){
      /////   Found correct index   /////
      if(localImageArray[i].url === url){
        /////   Changing isPrimaryImage if needed    /////
        if(localImageArray[i].isPrimaryImage){
          if(localImageArray.length !== 1){
            if(i-1 < 0){
              localImageArray[i+1].isPrimaryImage = true
              primaryImageUrl = localImageArray[i+1].url
            }
            else{
              localImageArray[i-1].isPrimaryImage = true
              primaryImageUrl = localImageArray[i-1].url
            }
          }
        
        }
        /////   Remove image    /////
        localImageArray.splice(i, 1)        
        break
      }
    }
    
    let justUrlsLocalImageArray = []
    for(let i=0; i<localImageArray.length; i++){
      justUrlsLocalImageArray.push(localImageArray[i].url)
    }

    // Updating photo urls in firestore
    const propRef = doc(db, 'properties', state.id)
    await updateDoc(propRef, {
      imageUrlArray: justUrlsLocalImageArray,
      primaryImageUrl: primaryImageUrl
    })

    //  Delete photo from firebase bucket
    deleteObject(ref(getStorage(), url)).then(() => console.log("deleted image")).catch((error) => alert("Error deleting image: " + error))
    setImageUploadLoading(false)
  }

  async function selectPrimaryPhoto(url, bool){

    var localImageArray = [...imageUrlArray]

    if(bool.target.checked){
      for(var i=0; i<localImageArray.length; i++){
        /////   Found correct index   /////
        if(localImageArray[i].url === url){
          localImageArray[i].isPrimaryImage = true
          const ref = doc(db, 'properties', state.id)
          await updateDoc(ref, {
            primaryImageUrl: localImageArray[i].url,
          })
        }
        else{
          localImageArray[i].isPrimaryImage = false
        }
      }
    }
    else{
      /////   Must have primary photo selected    /////
      alert("Must have a primary photo selected")
    }
    setImageUrlArray(localImageArray)
  }

  async function handleAddImage(event) {
    setImageUploadLoading(true)
    let localImageArray = imageUrlArray
    for(let i=0; i<event.target.files.length; i++){
      const object = event.target.files[i]
      const storageRef = ref(getStorage(), `${object.name}`)
      await uploadBytes(storageRef, object)
      .then(async () => {
        const url = await getDownloadURL(ref(getStorage(), storageRef))
        .catch((error) => alert('Error: Issue getting download url '+ error))
        const image = {
          url: url,
          isPrimaryImage: false
        }
        localImageArray.push(image)
      })
    }

    let justUrlsLocalImageArray = []
    for(let i=0; i<localImageArray.length; i++){
      justUrlsLocalImageArray.push(localImageArray[i].url)
    }

    setTimeout(async () => {
      const propRef = doc(db, 'properties', state.id)
      await updateDoc(propRef, {
        imageUrlArray: justUrlsLocalImageArray,
      })
      .then(() => setImageUploadLoading(false))
      // setOpenLoading(false)
    }, "1750")
  }

  async function deleteProperty(){
    await deleteDoc(doc(db, "properties", state.id))
    .then(() => navigate("/properties", { replace: true }))
  }

  function renderImage(index){
    if(imageUrlArray.length === index){
      return(
        <label style={{marginBottom: 10, marginRight: '20px', backgroundColor: '#efebf0', height: '120px', width: '120px', borderRadius: '5px', cursor: "pointer"}}>
          <input
            type="file"
            accept="image/*"
            onChange={(event) => handleAddImage(event)}
            style={{display: "none"}}
            disabled={imageUrlArray.length < 6 ? false : true}
            multiple="multiple"
          />
          <Box sx={selectSingleImageButton}>
            <div style={{paddingTop: '15%', fontSize: '42px', display: 'flex', justifyContent: 'center'}}>
              <CameraAltOutlinedIcon style={{fontSize: 'inherit'}}/>
            </div>
            <div style={{textAlign: 'center', fontSize: 14}}>Add up to 5 photos</div>
          </Box>
        </label>
      )
    }
    else if(imageUrlArray.length > index){
      return(
        <Box sx={{marginRight: '20px'}}>
          <img style={{height: '120px', width: '120px', borderRadius: '5px', display: 'block'}} src={imageUrlArray[index].url} alt='property'/>
          
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '25%'}}>
              <IconButton sx={{color: '#404040', fontSize: '20px', paddingTop: '9px', paddingLeft: 0}} onClick={() => deleteImage(imageUrlArray[index].url)} aria-label="delete">
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
            <Box sx={{marginLeft: '10%', width: '35%', display: 'flex', justifyContent: 'flex-end', marginTop: '8px'}}>
              {imageUrlArray[index].isPrimaryImage && <Typography style={{color: 'black', fontSize: '14px'}}>Primary</Typography>}
            </Box>
            <Box sx={{marginLeft: '10%', width: '20%', display: 'flex', justifyContent: 'flex-end'}}>
              <Checkbox color="success" sx={{color: grey[600],'&.Mui-checked': {color: grey[600]}, '& .MuiSvgIcon-root': {fontSize: 18}}} style={{marginBottom: '0px'}} inputProps={{ 'aria-label': 'controlled' }} checked={imageUrlArray[index].isPrimaryImage} onChange={(bool) => selectPrimaryPhoto(imageUrlArray[index].url, bool)} />
            </Box>
          </Box>
        </Box>
      )
    }
    else{
      return(
        <Box sx={emptyImageSpace}/>
      )
    }
  }

  return(
    <Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <MainCard title='Address'>             
              <Typography sx={{marginTop: '10px'}}>
                {address}
              </Typography>
            </MainCard>
            <MainCard title='Images'>
              <Box sx={{height: '290px'}}>
                <Box style={{display: 'flex', flexWrap: 'wrap', marginLeft: '3%', marginTop: '3%'}}>
                  {renderImage(0)}
                  {renderImage(1)}
                  {renderImage(2)}
                  {renderImage(3)}
                  {renderImage(4)}
                </Box>
                {imageUploadLoading && (
                  <CircularProgress size="2rem" style={{marginTop: '35px'}}/>
                )}
              </Box>
          </MainCard>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <MainCard title='Renter'>
              <Box sx={{height: '160px'}}>
                <Typography sx={{fontSize: 15}}>
                  {ownerName}
                </Typography> 
                <Typography sx={{fontWeight: 'bold', fontSize: '16px', marginTop: '15px'}}>
                  CONTACT INFORMATION
                </Typography>     
                <Typography sx={{fontSize: 15}}>
                  {ownerEmail}
                </Typography>
                <Typography sx={{fontSize: 15}}>
                  {ownerPhone}
                </Typography>
              </Box>
            </MainCard>
            <Grid mt={1} container spacing={3}>
              <Grid item xs={12} md={3}>
                <MainCard title='Monthly Rent'>             
                  <Typography>
                    ${monthlyRent.toLocaleString()}
                  </Typography>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <MainCard title='Lease Length'>             
                  <Typography>
                    {leaseLength} months
                  </Typography>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <MainCard title='Bedrooms'>             
                  <Typography>
                    {bedrooms} bedrooms
                  </Typography>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <MainCard title='Bathrooms'>             
                  <Typography>
                    {bathrooms} bathrooms
                  </Typography>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MainCard title='Type'>             
                  <Typography>
                    {propertyType}
                  </Typography>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MainCard title='Square Footage'>             
                  <Typography>
                    {sqft} sqft.
                  </Typography>
                </MainCard>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>{"Deleting Property"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: 'black'}}>
            Are you sure you want to delete this property?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{color: 'black'}} onClick={() => setDeleteDialogOpen(false)}>No</Button>
          <Button style={{color: 'black'}} onClick={() => deleteProperty()} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}