import React from 'react'

import {
  Box,
  Typography,
} from '@mui/material'

export default function NotFound() {
  return(
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <Typography style={{fontWeight: 'bold', fontSize: 20, marginLeft: '1%', marginTop: '1%'}}>
        Oh no! We couldn't find this page. Try navigating to a different link
      </Typography>
    </Box>
  )
}