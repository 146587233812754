import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  InputAdornment,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

 import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

import dayjs from 'dayjs'

import {db} from './firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 

const auth = getAuth();
const theme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
          CV Manages Properties
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {

  const navigate = useNavigate()

  const [loadingSignUp, setLoadingSignUp] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [ecPhoneNumber, setEcPhoneNumber] = useState("")
  const [typeOfService, setTypeOfService] = useState("Essential")
  const [isFirstPayment, setIsFirstPayment] = useState("All in first payment")
  const [numOfPaymentsBrokenDown, setNumOfPaymentsBrokenDown] = useState(2)

  async function handleSignUp(event){
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    if(data.get('ec-name') === '' || data.get('ec-phone') === '' || data.get('ec-relationship') === '' || data.get('phone') === '' || data.get('email') === '' || data.get('password') === '' || data.get('firstName') === '' || data.get('lastName') === ''){
      setErrorMessage('Error: All inputs must be filled in')
      setTimeout(() => setErrorMessage(''), 6000)
      return
    }

    setLoadingSignUp(true)

    createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
    .then(async (userCredential) => {

      //  Creating stipe connected account
      let connectedAccountId = ''
      const idToken = await userCredential.user.getIdToken(true)
      await fetch('https://us-central1-cv-manages-properties-4680d.cloudfunctions.net/createAccount/create-express-account', {
        method: 'POST',
        body: JSON.stringify({
          idToken: idToken,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .catch((error) => {
          console.log(error)
      })
      .then((response) => response.json())
      .then((data) => {
        if(data.connectedAccountId){
          connectedAccountId = data.connectedAccountId
        }
      })

      await setDoc(doc(db, 'owners', userCredential.user.uid), {
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        phone: phoneNumber,
        serviceType: typeOfService,
        numOfPaymentsBrokenDown: isFirstPayment === 'All in first payment' ? 1 : numOfPaymentsBrokenDown,
        ecName: data.get('ec-name'),
        ecPhone: ecPhoneNumber,
        ecRelationship: data.get('ec-relationship'),
        amountInBank: 0,
        connectedAccountId: connectedAccountId,
        memberSince: dayjs().format('MM/DD/YYYY hh:mm a'),
        completedOnboarding: false,
        ownerId: userCredential.user.uid
      })
      navigate("/dashboard", { replace: true })
      setLoadingSignUp(false)
    })
    .catch((error) => {
      let errorMessage = ''
      if(error.message.includes('auth/invalid-email')){
        errorMessage = 'Error: Invalid email'
      }
      else if(error.message.includes('email-already-in-use')){
        errorMessage = 'Error: Email already in use'
      }
      else if(error.message.includes('weak-password')){
        errorMessage = 'Error: Password should be at least 6 characters'
      }
      else{
        errorMessage = error.message
      }
      setLoadingSignUp(false)
      setErrorMessage(errorMessage)
      setTimeout(() => setErrorMessage(''), 6000)
    })
  }

  function formatPhoneNumber(value){
    if (!value) {
      return value
    }
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) {
      return phoneNumber
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  function changePhoneNumber(num){
    const formattedPhoneNumber = formatPhoneNumber(num)
    setPhoneNumber(formattedPhoneNumber)
  }

  function formatEcPhoneNumber(value){
    if (!value) {
      return value
    }
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) {
      return phoneNumber
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  function changeEcPhoneNumber(num){
    const formattedPhoneNumber = formatEcPhoneNumber(num)
    setEcPhoneNumber(formattedPhoneNumber)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Owner Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  value={phoneNumber}
                  onChange={(event) => changePhoneNumber(event.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid sx={{marginTop: '30px'}} item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Typography sx={{paddingTop: '5px'}} variant="h6">
                    Type of Service
                  </Typography>
                  <IconButton sx={{fontSize: '20px'}} href='https://cvmanagesproperties.com/packages/' target='_blank'>
                    <InfoOutlinedIcon fontSize='inherit' />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <FormControl>
                    <RadioGroup value={typeOfService} onChange={(event) => setTypeOfService(event.target.value)} row>
                      <FormControlLabel value="Essential" control={<Radio />} label="Essential" />
                      <FormControlLabel value="Growth" control={<Radio />} label="Growth" />
                      <FormControlLabel value="Premium" control={<Radio />} label="Premium" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Link color="inherit" href="https://cvmanagesproperties.com/packages/" target='_blank'>
                  More info
                </Link>
              </Grid>
              <Grid sx={{marginTop: '30px'}} item xs={12}>
                <Typography sx={{textAlign: 'center', paddingTop: '10px'}} variant="h6">
                  Maintenance Bank
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{textAlign: 'center'}}>
                  CV Manages will hold $1,000 in a maintenance bank for work orders. You have the option to have this amount 
                  taken out of the first payment for rent or have it broken down across more than 1 rent payment.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <FormControl>
                    <RadioGroup value={isFirstPayment} onChange={(event) => setIsFirstPayment(event.target.value)} row>
                      <FormControlLabel value="All in first payment" control={<Radio />} label="All in first payment" />
                      <FormControlLabel value="Broken down" control={<Radio />} label="Broken down" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              {isFirstPayment === "Broken down" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Number of payments</InputLabel>
                    <Select
                      value={numOfPaymentsBrokenDown}
                      label="Number of payments"
                      onChange={(event) => setNumOfPaymentsBrokenDown(event.target.value)}
                    >
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
          
              <Grid sx={{marginTop: '30px'}} item xs={12}>
                <Typography sx={{textAlign: 'center', paddingTop: '10px'}} variant="h6">
                  Emergency Contact
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="ec-name"
                  label="Name"
                  name="ec-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="ec-phone"
                  label="Phone"
                  name="ec-phone"
                  autoComplete="phone"
                  value={ecPhoneNumber}
                  onChange={(event) => changeEcPhoneNumber(event.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}  
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="ec-relationship"
                  label="Relationship"
                  name="ec-relationship"
                />
              </Grid>
              {errorMessage !== '' && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
             
              {/*<Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: '40px' }}
              disabled={loadingSignUp}
            >
              {loadingSignUp ? (
                <CircularProgress style={{color: 'white'}} size="1.3rem" />
              ) : (
                <Typography>
                  Sign Up 
                </Typography>            
              )}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}